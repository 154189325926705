// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-datasets-index-tsx": () => import("./../../../src/pages/datasets/index.tsx" /* webpackChunkName: "component---src-pages-datasets-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-papers-index-tsx": () => import("./../../../src/pages/papers/index.tsx" /* webpackChunkName: "component---src-pages-papers-index-tsx" */),
  "component---src-pages-tools-index-tsx": () => import("./../../../src/pages/tools/index.tsx" /* webpackChunkName: "component---src-pages-tools-index-tsx" */),
  "component---src-pages-tutorials-index-tsx": () => import("./../../../src/pages/tutorials/index.tsx" /* webpackChunkName: "component---src-pages-tutorials-index-tsx" */),
  "component---src-templates-dataset-template-js": () => import("./../../../src/templates/datasetTemplate.js" /* webpackChunkName: "component---src-templates-dataset-template-js" */),
  "component---src-templates-paper-template-js": () => import("./../../../src/templates/paperTemplate.js" /* webpackChunkName: "component---src-templates-paper-template-js" */),
  "component---src-templates-tool-template-js": () => import("./../../../src/templates/toolTemplate.js" /* webpackChunkName: "component---src-templates-tool-template-js" */),
  "component---src-templates-tutorial-template-js": () => import("./../../../src/templates/tutorialTemplate.js" /* webpackChunkName: "component---src-templates-tutorial-template-js" */)
}

